
import { defineComponent } from 'vue';

import useAuth, { applyForm, googleProfile, createTokenByGoogleIdToken } from '@/composable/auth';
import { RouteRecord } from '@/router';
import { ApplyFormStatusEnum } from '@/view-models/auth.vm';

const { logout } = useAuth();

export default defineComponent({
  setup() {
    return {
      googleProfile,

      logout,
    };
  },
  async beforeRouteEnter(to, from, next) {
    // console.log(from.name, to.name);

    const bool = await createTokenByGoogleIdToken();
    if (!bool) {
      return next({ name: RouteRecord.Login.name });
    }

    const { isMobileVerified, status, isAccepted } = applyForm.value;
    // console.log(isMobileVerified, status, isAccepted);
    const isPending = ApplyFormStatusEnum.pending === status;
    const isDeny = ApplyFormStatusEnum.deny === status;

    if (isAccepted) {
      return next({ name: RouteRecord.Home.name });
    }

    if (to.name !== RouteRecord.SignUpSms.name && !isMobileVerified) {
      return next({ name: RouteRecord.SignUpSms.name });
    }

    if (to.name !== RouteRecord.SignUpForm.name && isMobileVerified && status === ApplyFormStatusEnum.draft) {
      return next({ name: RouteRecord.SignUpForm.name });
    }

    if (to.name !== RouteRecord.SignUpDone.name && isPending) {
      return next({ name: RouteRecord.SignUpDone.name });
    }

    if (![RouteRecord.SignUpForm.name, RouteRecord.SignUpDone.name].includes(to.name) && (isDeny || isPending)) {
      return next({ name: RouteRecord.SignUpDone.name });
    }

    next();
  },
});
